import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import Lightbox from "../components/lightBox";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Hero from "../components/HeroBanner";
import {
  FaChartBar,
  FaExchangeAlt,
  FaFileCode,
  FaMobile,
} from "react-icons/fa";
import {
  FcCalendar,
  FcConferenceCall,
  FcInspection,
  FcCrystalOscillator,
  FcDownload,
  FcTimeline,
  FcCurrencyExchange,
  FcDocument,
  FcVoicePresentation,
  FcIphone,
  FcTodoList,
  FcBinoculars,
  FcPositiveDynamic,
  FcCollaboration,
  FcRating,
  FcBarChart,
  FcFeedback,
  FcViewDetails,
  FcAcceptDatabase,
  FcDoughnutChart,
  FcDatabase,
  FcSynchronize,
  FcComboChart,
  FcWorkflow,
  FcSettings,
  FcFile,
  FcAutomatic,
  FcPlus,
  FcSurvey,
  FcFilingCabinet,
  FcContacts,
  FcMoneyTransfer,
  FcAddressBook,
  FcPackage,
  FcList,
  FcLock,
  FcCalculator,
  FcCursor,
  FcBookmark,
  FcComments,
  FcShipped,
  FcPieChart,
  FcSupport,
  FcMindMap,
  FcInternal,
  FcSms,
} from "react-icons/fc";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    serwis: file(relativePath: { eq: "sp-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    laptop: file(relativePath: { eq: "lap-branze.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
          heroBg: file(
      relativePath: { eq: "system-crm-questy.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
        programserwis: file(relativePath: { eq: "questy-program-do-serwisu-serwisplanner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        tablica: file(relativePath: { eq: "kanban-zlecen.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
            produkt: file(relativePath: { eq: "baza-urzadzen-sp.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
                kalendarz: file(relativePath: { eq: "kalendarz-new-menu.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Aura = ({ data }) => {
  return (

       <Layout>
      <SEO
        title="Program do serwisu – zarządzanie usługami – dedykowane i gotowe systemy do serwisu"
        description="Program do serwisu ✅ Zarządzaj usługami dzięki dedykowanemu i komplementarnemu dla ERP rozwiązania do zarządzania serwisem oraz gotowym systemem o wielu możliwościach"
      />
         <Hero heroImage={data.heroBg} justifyContent="center">
        <h1>Zarządzanie usługami z programem do serwisu</h1>
        <h2>Skuteczne zarządzanie zleceniami serwisowymi</h2>
      </Hero>
  <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
  <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
           textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system do serwisu w Twoim biznesie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu</p>
   
      <div 
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej zarządzać zleceniami
            </Link>
          </div>
          </div>
    </section>

       <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>
  Zarządzanie serwisem, zleceniami czy innymi podobnymi usługami to wymagające zadanie, które przysparza 
  kłopotów wielu kierownikom. Wraz z&nbsp;rozwojem firmy i nieustannie rosnącą liczbą zleceń, konieczne może 
  okazać się usystematyzowanie pracy<strong> programem do serwisu</strong>. Głównym zadaniem takiego systemu jest przede 
  wszystkim<strong> sprawna obsługa zleceń </strong>na każdym ich etapie i wsparcie efektywności w całej firmie za pośrednictwem 
  <strong> usprawniania pozostałych kluczowych procesów</strong>. Z programem do serwisu będziesz w stanie szybko reagować na wszystkie 
  pojawiające się problemy i&nbsp;jeszcze lepiej zadbasz o&nbsp;relacje z Twoimi klientami.</p>
</div>
</div>
</Container>
       <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>
Stale dokładamy wszelkich starań, by móc możliwie najlepiej wspierać firmy na każdym etapie rozwoju, niezależnie od 
branży, w której one działają. Z&nbsp;tego powodu, w obszarze zarządzania zleceniami zdecydowaliśmy się na stworzenie 
dwóch różnych programów do serwisu. Pierwszy z nich to&nbsp;narzędzie gotowe i stosunkowo proste, dzięki czemu jest w&nbsp;stanie 
odpowiadać na wszelkie najpowszechniejsze trudności i potrzeby przedsiębiorstw, które pragną efektywnie 
zarządzać swoimi zleceniami (<a href="https://serwisplanner.pl/">Serwis Planner</a>). 
Drugie narzędzie to<i> dedykowany system</i>, który poza<strong> kompleksową obsługą serwisu </strong>oferuje szereg dodatkowych 
możliwości z&nbsp;zakresu zarządzania całą firmą i wsparcia najważniejszych jej procesów (<a href="https://aurabusiness.pl/">Aura Business</a>).
Oba te systemy wspierające zarządzanie zleceniami cechuje przede wszystkim ich ogromna<strong><i> elastyczność</i></strong>, 
a&nbsp;nas, w&nbsp;Questy – zdobywane latami<strong><i> doświadczenie </i></strong>we&nbsp;wdrażaniu rozwiązań w&nbsp;setkach firm 
działających w&nbsp;wielu różnych branżach.
</p>
</div>
          <div className="text-block" style={{
          paddingTop: 20,
        }}>
            <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.laptop]}
        alts={["Program do serwisu - zarządzanie usługami i zleceniami, Serwis Planner"]}
      />
</div>
</div>
</Container>
   <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>
Już od 2002 roku<strong> niesiemy kompleksową pomoc przedsiębiorstwom małego i średniego sektora oraz większym firmom, które 
działają na szeregu różnych rynków wielu krajach świata</strong>. Wspieramy firmy zajmujące się serwisem i/lub sprzedażą 
maszyn i urządzeń, serwisem instalatorskim (różnego typu – instalacje elektryczne, wodne, systemy PPOŻ, panele 
  fotowoltaiczne itd.) oraz działające w wielu innych branżach. Od lat wysłuchujemy potrzeb i&nbsp;problemów naszych 
klientów, co pozwala nam nieprzerwanie rozwijać nasze programy do serwisu i&nbsp;usprawniać ich działanie. 
Kierujemy się również przekonaniem, że to program do serwisu powinien dostosować się do firmy, a nie ona do 
niego. Dzięki temu wszystkiemu nie mamy żadnych wątpliwości, że&nbsp;oferowane przez nas rozwiązanie nie tylko 
wesprze Twoją firmę w kompleksowym zarządzaniu wszystkimi zleceniami, ale też pozytywnie wpłynie na&nbsp;jej 
efektywność, niwelując wiele dręczących ją problemów. </p>
</div>
</div>
</Container>

 <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p><strong>Nasze programy do serwisu stanowią odpowiedź na wszystkie najbardziej dokuczliwe problemy w&nbsp;obsłudze zleceń:</strong></p>
<p><ul>
<li>Czasochłonne delegowanie zleceń (konieczność wielu telefonów, pojawiające się błędy, zapominanie adresu, itd.).</li>
<li>Problemy z monitorowaniem zleceń, które aktualnie znajdują się w&nbsp;realizacji (informacje opóźnione, niekompletne).</li>
<li>Brak usystematyzowanej bazy danych o historii serwisowanych maszyn i&nbsp;urządzeń.</li>
<li>Chaos w kontaktach z klientami i brak dostępu do&nbsp;historii kontaktów.</li>
<li>Czasochłonny proces generowania faktur lub&nbsp;zapominanie o rozliczeniach.</li>
<li>Niewydajne wykorzystanie czasu serwisantów.</li>
<li>Konieczność ręcznego generowania raportów serwisowych.</li>
<li>Problemy w monitorowaniu efektywności pracowników, wykonywanych przez nich zleceń oraz godzin spędzonych na pracy.</li>
<li>Brak dostępu do wiedzy o wykorzystanych przez serwisantów częściach.</li>
<li>Serwisant otrzymując zlecenie nie miał kompletu informacji w jednym miejscu przez co 
kontaktował się wielokrotnie z kierownikiem lub zapominał o&nbsp;wykonaniu niektórych czynności.</li>
</ul></p>
</div>
  <div className="text-block">
  <p><strong>Co zyskasz wdrażając program do serwisu w&nbsp;Twoim przedsiębiorstwie?:</strong></p>
<p><ul>
<li>Bazę wszystkich urządzeń przypisanych określonym klientom, wraz z całą historią realizowanych względem niego zleceń.</li>
<li>Narzędzie do kompleksowej koordynacji pracy serwisantów.</li>
<li>Sposób na błyskawiczne delegowanie zleceń za&nbsp;pomocą aplikacji mobilnej.</li>
<li>Kompletną bazę wiedzy o zleceniach i klientach.</li>
<li>Możliwość sprawdzania poziomu realizacji poszczególnych zleceń.</li>
<li>System umożliwiający mierzenie rentowności podejmowanych działań.</li>
<li>Metodę sprawnego monitorowania rozliczeń.</li>
<li>Możliwość automatycznego tworzenia raportów z&nbsp;wykonanej usługi.</li>
<li>Narzędzie do weryfikowania zużytych części i&nbsp;zasobów w trakcie zlecenia.</li>
<li>System do rejestrowania przez serwisantów poświęconego na zlecenie czasu.</li>
<li>Obsługę podpisu elektronicznego w ramach potwierdzania realizacji poszczególnych etapów zlecenia.</li>
<li>Sprawną komunikację wewnątrz organizacji oraz&nbsp;na zewnątrz – z klientem.</li>
<li>Możliwość samodzielnego rejestrowania zleceń przez kontrahentów.</li>
</ul></p>          
</div>
</div>
</Container>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
  <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
           textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system do serwisu w Twoim biznesie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu</p>
   
      <div 
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej zarządzać zleceniami
            </Link>
          </div>
          </div>
    </section>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
 <h2
 style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}
  >Część z kluczowych funkcji programu do serwisu:</h2>

<p>Program do serwisu to kompleksowe narzędzie, które za pomocą wielu różnych funkcji umożliwia 
<strong> efektywne zarządzanie zleceniami serwisowymi</strong>. Od&nbsp;błyskawicznego tworzenia zleceń i wydajnego 
zarządzania nimi, przez gromadzenie wiedzy o&nbsp;klientach i serwisowanych urządzeniach oraz&nbsp;wsparcie 
kalendarza i harmonogramu zleceń, aż po&nbsp;narzędzia wspierające ofertowanie i fakturowanie – przedstawiamy 
niektóre z kluczowych funkcji naszego programu do serwisu.
</p>
</div>

</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<h3>Efektywne zarządzanie zleceniami</h3>

<p>Nasze programy do serwisu pozwalają na sprawne i&nbsp;efektywne zarządzanie wszelkiego typu zleceniami, takimi 
jak np. serwis, instalacja, przegląd, reklamacje oraz wiele innych. Z łatwością możesz za jego pośrednictwem 
<strong> obsłużyć wiele własnych rodzajów procesu</strong>, posiadających różne etapy, atrybuty czy numerację. Dzięki 
uporządkowaniu zgodnie z typami i&nbsp;odpowiednim dostosowaniu karty danego zlecenia do specyfiki 
procesu w&nbsp;Twojej firmie, osiągnie ona<strong> jeszcze lepsze wyniki efektywności</strong>.
</p>
<p>Panel do zarządzania zleceniami to świetny sposób na usprawnione planowanie i delegowanie 
pracy w&nbsp;zespole, monitorowanie aktualnego przepływu, poziomu oraz czasu realizacji poszczególnych 
zleceń, upłynnienia komunikacji dzięki powiązanym wiadomościom wewnętrznym oraz&nbsp;weryfikowania 
historii zmian na karcie zlecenia serwisowego.</p>

</div>
    <div className="text-block">
    <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.tablica]}
        alts={[
          "Tablica zleceń - widok kanban w programie do serwisu",
        ]}
      />
    </div>
</div>
</Container>
 <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
    <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.produkt]}
        alts={[
          "Panel produktu serwisowanego w programie do zarządzania zleceniami",
        ]}
      />
</div>
    <div className="text-block">
    <h3>Baza wiedzy o serwisowanych urządzeniach</h3>
<p>W programie do serwisu z łatwością stworzysz<strong> spójną i kompleksową bazę danych </strong>obejmującą 
wszystkie serwisowane przez Twoją firmę maszyny i&nbsp;urządzenia. Każdy z produktów przypisany 
może zostać do indywidualnego klienta i posiadać swój własny, unikatowy numer. Karta produktu 
serwisowanego obejmie również cały jego opis, wraz z techniczną specyfikacją i wszystkimi 
dodatkowymi atrybutami. Znajdziesz w niej również wszystkie zlecenia powiązane z daną maszyną, 
które zostały dotychczas względem niej wykonane lub też zostały zaplanowane na przyszłość.
</p>
<p>Program do serwisu pozwoli Ci na użycie dowolnych kryteriów w opisywaniu serwisowanych produktów – w karcie 
danego produktu znajdą się dzięki temu wszystkie informacje istotne w przypadku wykonywania usługi serwisowej. 
Wbudowana wyszukiwarka systemu umożliwi również szybkie dotarcie do wszystkich  potrzebnych danych i&nbsp;informacji. </p>
    </div>
</div>
</Container>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">

<h3>Kalendarz zleceń i koordynacja pracy</h3>
<p>
Program do serwisu to ergonomiczne narzędzie, które pozwoli Ci na holistyczne zarządzanie 
kalendarzem zleceń. Dzięki<strong> współdzielonym kalendarzom </strong>możesz jako kierownik: wyświetlić 
wszystkie zaplanowane zlecenia dowolnych pracowników i sprawdzić obłożenie danej osoby 
zleceniami lub znaleźć serwisanta, który ma czas by podjąć dany serwis. A&nbsp;także zweryfikować 
które ze&nbsp;zleceń już są w realizacji i na jakim znajdują się etapie oraz tworzyć nowe zlecenia 
swoim pracownikom. Wiemy, że typy zleceń mogą znacznie się między sobą różnić 
(tak jak np. instalacja od&nbsp;przeglądu). System pozwala więc definiować wszystkie pola 
informacji, które dla danego serwisu są niezbędne, dzięki czemu Twoi serwisanci otrzymają 
jedynie te dane, które faktycznie im się przydadzą.
</p>

</div>
    <div className="text-block">
    <Lightbox
        style={{
          maxWidth: 1000,
          paddingTop: 25,
          margin: "0 auto",
        }}
        images={[data.kalendarz]}
        alts={[
          "Panel klienta niezalogowanego w systemie sprzedażowym B2B",
        ]}
      />
    </div>
</div>
</Container>
<Container>
         <div className="text-block">
 <p>
  Podczas<strong> delegowania zleceń serwisantom </strong>masz stały dostęp do ich 
  kalendarzy i już zleceń, które już zostały zaplanowane. Po przypisaniu 
  pracownika do&nbsp;danego zlecenia pojawi się ono jednocześnie w&nbsp;jego<strong> aplikacji 
  mobilnej </strong>oraz na<strong> tablicy kanban </strong>w&nbsp;pełnej wersji systemu 
  (dostępnej z poziomu przeglądarki internetowej). </p>
</div>
</Container>

<h3
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Sprawdź też inne najważniejsze funkcje programu do serwisu</h3>

  <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
                  <Tile>
            <FcIphone size="70px" />
            <br />
            <h3>Aplikacja dla serwisanta</h3>
            <p>
              Sprawna i wygodna praca serwisantów w&nbsp;terenie i&nbsp;monitorowanie ich aktywności</p>
          </Tile>
          <Tile>
            <FcSupport size="70px" />
            <br />
            <h3>
              Szybkie
              <br />
              tworzenie
              <br />
              zleceń
            </h3>
            <p>Możliwość błyskawicznego dodania zlecenia, produktu i&nbsp;klienta</p>
          </Tile>
          <Tile>
            <FcInspection size="70px" />
            <br />
            <h3>
              Zlecenia
              <br />
              cykliczne
            </h3>
            <p>
              Zautomatyzowane zapisywanie cyklicznych zleceń w danym odstępie czasu (np.&nbsp;przeglądów)
            </p>
          </Tile>
          <Tile>
            <FcSurvey size="70px" />
            <br />
            <h3>Wystawianie protokołów serwisowych</h3>
            <p>
              Brak konieczności ręcznego przepisywania danych oraz&nbsp;większa efektywność pracy</p>
          </Tile>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
            <Tile>
            <FcSynchronize size="70px" color="#999999" />
            <br />
            <h3>Integracje z ERP</h3>
            <p>
              Zaautomatyzowana wymiana danych i&nbsp;dokumentów z programem ERP
            </p>
          </Tile>
           <Tile>
            <FcTimeline size="70px" color="#999999" />
            <h3>
              Harmonogram
              <br />
              zleceń
            </h3>
            <p>
              Efektywna i wygodna weryfikacja obciążenia pracowników zleceniami
            </p>
          </Tile>
              <Tile>
            <FcContacts size="70px" color="#999999" />
            <br />
            <h3>Baza wiedzy o&nbsp;klientach</h3>
            <p>
              Baza danych z wszystkimi kluczowymi informacjami o&nbsp;klientach oraz&nbsp;współpracy
            </p>
          </Tile>
          <Tile>
            <FcMoneyTransfer size="70px" color="#999999" />
            <br />
            <h3>Fakturowanie i&nbsp;płatności</h3>
            <p>
            Automatyczna obsługa faktur – oszczędność czasu i&nbsp;większa wygoda pracowników
            </p>
          </Tile> 
         
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
         <Tile>
            <FcShipped size="70px" color="#999999" />
            <br />
            <h3>Planowanie zleceń na mapie</h3>
            <p>
              Optymalizacja kolejności wykonywania zleceń i&nbsp;planowania poszczególnych tras
            </p>
          </Tile>
          <Tile>
            <FcSms size="70px" color="#999999" />
            <br />
            <h3>Powiadomienia SMS</h3>
            <p>
             Automatyzacja wysyłki powiadomień SMS do serwisantów, kontrahentów lub&nbsp;kierownika
            </p>
          </Tile>
          <Tile>
            <FcAddressBook size="70px" color="#999999" />
            <br />
            <h3>Powiadomienia mailowe</h3>
            <p>
              Automatyczne powiadomienia mailowe po&nbsp;zmianie etapu w danym zleceniu
            </p>
          </Tile> 
          <Tile>
            <FcList size="70px" color="#999999" />
            <br />
            <h3>Zamówienia pracowników</h3>
            <p>
              Sprawne zgłaszanie potrzeb i wygodne gospodarowanie częściami
            </p>
          </Tile> 
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
         <Tile>
            <FcLock size="70px" color="#999999" />
            <br />
            <h3>Uprawnienia</h3>
            <p>Bezpieczeństwo danych i&nbsp;elastyczne zarządzanie dostępem</p>
          </Tile>
          <Tile>
            <FcComments size="70px" color="#999999" />
            <br />
            <h3>Komunikator</h3>
            <p>Szybka komunikacja i&nbsp;wymiana informacji pomiędzy pracownikami</p>
          </Tile>
          <Tile>
            <FcPieChart size="70px" color="#999999" />
            <br />
            <h3>Raporty w&nbsp;programie do&nbsp;serwisu</h3>
            <p>Sposób na analizę kosztów i&nbsp;aktywności poszczególnych serwisantów</p>
          </Tile>
          <Tile>
            <FcCalculator size="70px" color="#999999" />
            <br />
            <h3>Ofertowanie</h3>
            <p>Błyskawiczne i&nbsp;automatyczne tworzenie ofert handlowych dla kontrahentów</p>
          </Tile>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
         <Tile>
            <FcCursor size="70px" color="#999999" />
            <br />
            <h3>Panel klienta</h3>
            <p>Miejsce dla klientów na rejestrację zleceń oraz&nbsp;sprawdzanie ich statusu i&nbsp;historii
            </p>
          </Tile>
          <Tile>
            <FcBookmark size="70px" color="#999999" />
            <br />
            <h3>Personalizacja pulpitu</h3>
            <p>
              Elastyczna konfiguracja widgetów – dostęp do&nbsp;wszystkich kluczowych informacji
            </p>
          </Tile>
          <Tile>
            <FcFilingCabinet size="70px" color="#999999" />
            <br />
            <h3>Magazyn i&nbsp;tworzenie dokumentów</h3>
            <p>Możliwość bieżącego kontrolowania stanów magazynowych i&nbsp;cen</p>
          </Tile>
          <Tile>
            <FcPackage size="70px" color="#999999" />
            <br />
            <h3>Produkty i usługi</h3>
            <p>
              Uporządkowane gromadzenie danych o&nbsp;wszystkich produktach i&nbsp;usługach
            </p>
          </Tile>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcMindMap size="70px" color="#999999" />
            <br />
            <h3>Tworzenie i graficzne modelowanie procesów</h3>
            <p>
              Możliwość obsługi szeregu procesów składających się z wielu różnych faz i pól
            </p>
          </Tile>
                    <Tile>
            <FcInternal size="70px" color="#999999" />
            <br />
            <h3>Pobieranie danych kontrahenta z GUS</h3>
            <p>Zautomatyzowane pobieranie danych dot. klienta z bazy GUS</p>
          </Tile>
        </div>
      </Container>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
  <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
           textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system do serwisu w Twoim biznesie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu</p>
   
      <div 
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej zarządzać zleceniami
            </Link>
          </div>
          </div>
    </section>
<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Co wyróżnia nasze programy do serwisu?</h2>
 <Container>
      <BenefitBlockWrapper>
       <BenefitBlock
          icon={<FaMobile size="70px" color="#86BC2A" />}
          title="Mobilność – program dostępny jest w&nbsp;aplikacji mobilnej oraz&nbsp;w&nbsp;każdym urządzeniu z Internetem"
        />
                <BenefitBlock
          icon={<FaExchangeAlt size="70px" color="#86BC2A" />}
          title="Integrowalność – oferujemy szereg integracji ze&nbsp;wszystkimi najpopularniejszymi systemami ERP"
        />
        <BenefitBlock
          icon={<FaChartBar size="70px" color="#86BC2A" />}
          title="Elastyczność – szeroki zakres możliwości samodzielnej konfiguracji systemu"
        />
        <BenefitBlock
          icon={<FaFileCode size="70px" color="#86BC2A" />}
          title="Rozwojowość – program do serwisu może zostać rozszerzony o moduł CRM lub B2B, a także sprawnie przejść na rozwiązanie dedykowane"
        />
      </BenefitBlockWrapper>
    </Container>

<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Czym różni się Serwis Planner od Aura Business? Kto z nich skorzysta?</h2>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p><strong>Serwis Planner </strong>jest gotowym narzędziem wspierającym<strong> kompleksowe zarządzanie serwisem </strong>o wielu 
możliwościach. Nie oznacza to jednak, że&nbsp;nie można go odpowiednio skonfigurować w zależności od 
specyfiki funkcjonowania danej firmy – program do serwisu<strong> sprawdza się bowiem w&nbsp;wielu branżach</strong>. 
Nie ma znaczenia, czy zarządzasz serwisem maszyn lub&nbsp;urządzeń, świadczysz usługi w&nbsp;zakresie PPOŻ 
czy naprawiasz elektronikę –<strong> elastyczność naszego rozwiązania pozwoli na&nbsp;dopasowanie go do&nbsp;potrzeb 
Twojej firmy</strong>, zwiększając tym samym wygodę użycia oraz&nbsp;skuteczność Twoich działań.</p>
</div>
 <div className="text-block"  style={{
                textAlign: "center",
                margin: "10px",
                      paddingTop: "100px",

              }}
            >
<a href="https://serwisplanner.pl/">
            <GatsbyImage
              image={data.serwis.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 270, 
                              
              }}
              alt="Program do serwisu - szybkie wdrożenie - Serwis"
            />
          </a>
 </div>
</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p><strong>Aura Business </strong>to nasze największe rozwiązanie, które przeznaczone jest dla dużych 
firm lub tych, które działają w niszowych branżach i mają niestandardowe potrzeby. 
System stawia na<strong> elastyczność i dopasowanie</strong>. Nie jest to wyłącznie narzędzie wspierające 
zarządzanie serwisem. Aura Business to dedykowane oprogramowanie, które<strong> wspiera 
sprzedaż, marketing i zarządzanie usługami</strong>. Wiemy jednak, że nasi klienci mogą 
chcieć korzystać tylko z niektórych oferowanych przez nas modułów lub też z zupełnie 
nowych, dlatego to<strong> klient decyduje o ostatecznym kształcie rozwiązania</strong>. W ten sposób 
zostaje ono idealnie dostosowane do potrzeb danego biznesu.
</p>
</div>
 <div className="text-block"  style={{
                textAlign: "center",
                margin: "10px",
                paddingTop: "60px",

              }}
          >
<a href="https://aurabusiness.pl/">
            <GatsbyImage
              image={data.aura.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 270, 
                              
              }}
              alt="Aura Business - dedykowany program do serwisu"
            />
          </a>
</div>
</div>
</Container>
<Container>
         <div className="text-block">
 <p>
  Nie wiesz, jakie rozwiązanie sprawdzi się najlepiej w&nbsp;Twojej firmie? Napisz do nas – szerzej 
  omówimy każdy z naszych systemów i wesprzemy Cię w&nbsp;wyborze odpowiedniego rozwiązania dla Twojego biznesu.</p>
</div>
</Container>


<Container className="container container--text">
<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
  }}
  >Nagrywamy dla Ciebie – dzielimy się doświadczeniem</h2>
    <div className="text-answers">
         <div className="text-block">
         <p>  W zespole Questy nie boimy się żadnych wyzwań i&nbsp;ekscytujących zadań. 
         Jednym z nich było również występowanie przed kamerą w&nbsp;ramach nagrywanych 
         przez nas vlogów. :) Materiały, które tworzymy w&nbsp;zakresie pracy z programem do 
          serwisu obejmują tematykę szeroko rozumianego efektywnego zarządzania serwisem online. 
          Oto wiedza i&nbsp;doświadczenie, które zdobywaliśmy przy wdrażaniu w firmach programów do serwisu 
          przez kilkanaście lat.</p>
</div>
</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
        <h3>Zlecenia cykliczne: Jak nimi zarządzać?</h3>

<p> Pierwszy z naszych filmów dotyczących efektywnego zarządzania pracą serwisu poświęcony jest zleceniom 
cyklicznym. Jak sprawnie nimi zarządzać? Co zrobić, by nie zapominać o planowanych przeglądach, które 
powinieneś wykonać u swojego klienta?</p>
<p> W tym ponad 20-minutowym materiale Marta Kaźmierska, Business Development 
Manager w&nbsp;Questy, udziela odpowiedzi na wszystkie te i wiele innych pytań. 
Obejrzyj ten odcinek i dowiedz się, jak zapanować nad cyklicznymi zleceniami w Twojej firmie.</p>
</div>
         <div className="text-block">
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="TjlbbeCC6Xo"
              title="Obsługa zleceń cyklicznych - jak to robić?"/>
          </div>
</div>
</Container>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
        <h3>7 problemów Twojego serwisu – jak je rozwiązać?</h3>

<p>Z pewnością wiesz, że praca w serwisie oraz zarządzanie serwisantami wiążą 
się z wieloma problemami. Nasi klienci często skarżą się na chaos i&nbsp;problemy 
z porządkowaniem i koordynacją pracy w&nbsp;serwisie, automatyzacją 
poszczególnych procesów firmy czy nieefektywną komunikacją. </p>
<p>Wojciech Nosal, Business Development Manager w&nbsp;Questy, omawia siedem takich 
najczęstszych problemów i przedstawia praktyczne rozwiązania, które pomogą je 
wyeliminować. Od karty kontrahenta i bazy urządzeń, aż po generowanie protokołów 
serwisowych i aplikację mobilną – dowiedz się, jak jeszcze lepiej możesz zarządzać Twoim serwisem.</p>
</div>
   <div className="text-block" style={{
                padding: "30px",
              }}>
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="BNmPHQuxvA0"
              title="Jak rozwiązać najczęstsze problemy w serwisie?"
            />
          </div>
</div>
</Container>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
    <h3>System do Obsługi Zgłoszeń Serwisowych: Usprawnij obsługę zleceń</h3>

<p>Dowiedz się, w jaki sposób program do serwisu może usprawnić proces obsługi zgłoszeń w Twojej firmie. 
Zorganizuj i usystematyzuj swój serwis tak, by praca nad poszczególnymi zleceniami przebiegała Tobie oraz&nbsp;Twoim 
serwisantom gładko i sprawnie. </p>
</div>
    <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="Pdvju_IiM2Y"
              title="Jak system do obsługi zgłoszeń serisowych może usprawnić obsługę zleceń?"
            />
          </div>
</div>
</Container>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
  <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
           textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system do serwisu w Twoim biznesie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu</p>
   
      <div 
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej zarządzać zleceniami
            </Link>
          </div>
          </div>
    </section>
    </Layout>
  );
};

export default Aura;
